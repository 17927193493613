.customModal {
  max-width: 64rem;
  width: 100%;
  margin: 0 auto;

  transition: all 300ms ease-in-out;
  form {
    padding: 0.5rem 2rem 2rem;
  }

  @media (min-width: 768px) {
    width: 80%;
  }

  > *:first-child {
    padding: 0;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 0.5rem;
  }
}
