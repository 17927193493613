.chat-setting-panel {
  transition:
    transform 0.2s ease-out,
    opacity 0.2s ease-in,
    opacity 0.1s ease-out;
  transform: translateX(-100%);
  opacity: 0.5;
}

.chat-setting-panel.open {
  transform: translateX(0);
  opacity: 1;
}
.chat-setting-panel-c {
  transition: opacity 0.5s ease-out;
  opacity: 0;
}

.chat-setting-panel-c.open {
  opacity: 1;
}
