@import 'components/global.scss';
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

$shadow-normal: 3px 0px 5px 0px rgba(0, 0, 0, 0.25);
$shadow-hover: 1.8em 0 5px -2px rgba(12, 37, 77, 0.05);
.sidebar {
  flex: 1 0 $scale7;
  z-index: 101;
  height: 100dvh;
  padding: $scale;
  overflow: hidden;
  background-color: $c-primary-dark-blue;
  transition: all 0.2s ease-out;
  box-shadow: 5px 0px 20px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 0px 20px 0px rgba(0, 0, 0, 0.5);
  @include box-shadow($shadow-normal);
  &:hover {
    @include box-shadow($shadow-hover);
    @media (max-width: $mobile) {
      @include box-shadow($shadow-normal);
    }
    .icon-toggle {
      opacity: 1;
    }
  }
  @media (max-width: $mobile) {
    position: fixed;
    width: 0;
    padding: 0;
    &.open {
      width: 80%;
      padding: $scale;
    }
  }
  @media (min-width: $mobile) and (max-width: $desktop) {
    font-size: 0.8em;
    flex: 1 0 5em;
  }

  .logo {
    @media (max-width: $mobile) {
      display: none;
    }
  }
  &.open {
    flex: 1 0 19em;
    a span.label,
    div span.label,
    .user-label {
      opacity: 1;
      transition: all 0.8s ease-in-out;
    }
    .nav-links {
      svg {
        display: inline-block;
        position: unset;
        transform: unset;
      }
    }
    .user-label {
      display: inline-block;
    }
    .icon-toggle {
      left: 18.2em;
      transform: translate(0, -50%) rotate(180deg);
      & > svg {
        margin-left: unset;
        margin-right: auto;
      }
    }
  }

  > a {
    position: absolute;
    top: $scale2;
    left: $scale2;
    width: $scale2;
    height: $scale2;
  }

  .nav-links {
    padding: 0;
    margin-top: $scale7;
    a svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    a {
      position: relative;
      display: block;
      // overflow: hidden;
      text-decoration: none;
      white-space: nowrap;
      color: white;
      cursor: pointer;
      width: 100% !important;
      height: 2.8em;
      padding: 0.8em 0.812em;
      margin: 5px 0;
      font-size: 1em;
      line-height: 1;
      @media (min-width: $mobile) and (max-width: $desktop) {
        padding: 0.68em 0.812em;
      }

      .icon {
        position: absolute;
        top: 50%;
        left: $scale-1;
        transform: translateY(-50%);
      }

      .label {
        opacity: 0;
        pointer-events: none;
        margin-left: $scale;
      }

      &.active,
      &:hover {
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: -1em;
          width: 4px;
          height: 1rem;
          transform: translateY(-50%);
          border-radius: 0 5px 5px 0;
          transition: all 0.2s ease-out;
        }
      }
      &:hover {
        border-radius: 10px;
        background-color: #ffffff10;
        &::before {
          background-color: #ffffff80;
        }
      }

      &.active {
        border-radius: 10px;
        background-color: #ffffff20;
        &::before {
          background-color: white !important;
        }
      }
    }
  }
}

.btn-togglenav {
  position: absolute !important;
  left: 1rem;
  top: 1.3rem;
  z-index: 12;
  @keyframes opacitySwitch {
    0%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0.4;
    }
  }
  &.icon-toggle {
    @media (min-width: $mobile) {
      top: 50%;
      width: 2em;
      left: 4.2em;
      opacity: 0;
      transform: translate(0, -50%);
      height: 100%;
      & > svg {
        margin-left: auto;
      }
      svg path:nth-child(1) {
        animation: opacitySwitch 1s infinite;
      }

      svg path:nth-child(2) {
        animation: opacitySwitch 1s infinite 0.5s;
      }
    }
  }
}
.popup {
  .avatar-btn {
    display: block;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    margin-right: auto;
    & > button {
      display: inline-block;
      vertical-align: middle;
    }
    .label {
      opacity: 0;
      display: inline-block;
      vertical-align: middle;
      margin-left: $scale;
      color: white;
    }
  }
}
