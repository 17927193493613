@import 'components/global.scss';

.header {
  .left,
  .right {
    position: absolute;
    height: 18px;
    width: 18px;
    top: 0;
    overflow: hidden;
  }

  .left {
    left: -18px;
  }

  .right {
    right: -18px;
  }

  .left::before,
  .right::before {
    content: '';
    position: absolute;
    top: 0;
    height: 200%;
    width: 200%;
    border-radius: 100%;
    box-shadow: 10px 10px 5px 100px rgb(255, 255, 255);
    z-index: -1;
  }
  .left::before {
    left: -100%;
  }
}
