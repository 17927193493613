.doc-body {
    font-family: serif;
}

.doc-main {
    text-align: justify;
    padding: 10px;
    margin-bottom: 50px;
}

.doc-quote {
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    background-color: #ebf1fa;
    border-left: #12367f 2px solid;
}

.doc-quote::before {
    content: '‟';
    color: #12367f;
    font-size: 30px;
    padding-right: 10px;
}

.doc-list-item {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 10px 0;
}

.doc-list-label {
    flex: 0 0 1.5em;
    font-weight: bold;
}

.doc-dl {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.doc-dt {
    float: left;
    clear: left;
    margin-right: 0.5em;
}

.doc-dd {
    margin-left: 2em;
    margin-bottom: 0.5em;
}

.doc-dl, .doc-dl {
    margin-left: 2em;
}

.doc-dl {
    margin-left: 2.5em;
}

.doc-manualMargin {
    margin: 2.5em 10px;
    padding: 0.5em 10px;
}

.doc-ul {
    padding: 0;
}

.doc-list-body {
    flex: 1;
    padding-left: 10px;
}

.doc-aside {

}

.doc-table {
    border-collapse: collapse;
    margin: 20px 0;
}

.doc-th {
    padding: 8px;
    vertical-align: text-top;
    text-align: left;
}

.doc-td {
    padding: 8px;
    vertical-align: text-top;
}

.doc-img {
    width: -webkit-fill-available;
}

.doc-ref{
    color: #12367F
}

.doc-h1 {
    color: #12367f;
    font-weight: bold;
    padding: 5px;
}

.doc-h2 {
    color: #12367f;
    font-weight: bold;
    padding: 5px;
}

.doc-h3 {
    color: #12367f;
    padding: 5px;
}

.doc-table-withBorder th, 
.doc-table-withBorder td {
    border: 1px solid #ddd !important;
    padding: 8px; /* Add some padding for better readability */
}

.doc-table-withBorder tbody{
    border: 1px solid #ddd;
}

.doc-table-withBorder tbody tr:last-child td {
    border-width: 1px !important;
}

.doc-hr{
    margin-top: 3%;
    margin-bottom: 2%;
}