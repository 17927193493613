@import 'components/global.scss';

.subnav {
  position: sticky;
  top: 0;
  z-index: 49;
  padding: $scale1 $scale2 0;
  background: white;
  margin-bottom: $scale2;
  border-top: 1px solid $c-border;
  @media (max-width: 768px) {
    z-index: 11;
    padding: $scale $scale1 0 $scale4;
  }
}

.item {
  color: $c-text;
  display: inline-block;
  padding-bottom: $scale;
  margin-right: $scale2;
  text-decoration: none;
  @media (max-width: 768px) {
    padding-bottom: $scale-3;
    margin-bottom: $scale-3;
  }
  &:hover {
    color: $c-primary-dark-blue;
  }

  &.active {
    font-weight: 500;
    color: $c-primary-dark-blue;
    border-bottom: 2px solid $c-primary-dark-blue;
  }
}
