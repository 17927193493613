input[type='checkbox'] {
  &:checked {
    border: none;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center center;
    background-image: url('./icons/ico-check-white.svg');
  }
}
div[data-expand='true'] svg {
  transform: rotate(180deg);
}
